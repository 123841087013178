import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faLock } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { updatePasswordApi } from './services/authentication';
import uttarakhanadLogo from '../assets/img/logo/uttarakhanda.png';
import SFALogo from '../assets/img/SFALogo.svg';

const initialValues = {
  new: '',
  confirm: '',
};

const validationSchema = Yup.object({
  new: Yup.string()
    .required('Please enter your new password')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'Password much contain 8 characters. Please include atleast one Uppercase character, one Lowercase character, one digit and a special character in your password.'
    ),
  confirm: Yup.string()
    .oneOf([Yup.ref('new')], 'Passwords do not match')
    .required('Please confirm your password'),
});

export default function SignIn() {
  const history = useHistory();
  const location = useLocation();

  const [invalid, setInvalid] = useState({
    invalid: false,
    msg: '',
  });

  useEffect(() => {
    if (location.search === '' || location.search.split('=')[0] !== '?key')
      history.push('/forgot-password');
  }, []);

  const updatePasswordMutation = useMutation((data) => updatePasswordApi(data), {
    onSuccess: (result) => {
      if (result.message === '/') history.push('/');
      else
        setInvalid({
          invalid: true,
          msg: result.message,
        });
    },
    onError: async (err) => {
      console.debug('Error - ', err);
    },
  });

  const handleForgotPassword = () =>
    async function (values, { setSubmitting }) {
      console.log(values);
      setSubmitting(true);
      const params = {
        password: values.new,
        key: location.search.split('=')[1],
      };
      await updatePasswordMutation.mutateAsync(params);
      setSubmitting(false);
    };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleForgotPassword()}
    >
      {({ isSubmitting }) => (
        <div className="flex flex-row w-screen h-screen">
          <div
            id="blue-bg"
            className="hidden h-screen p-15 lg:flex lg:w-1/2 flex justify-center items-center flex-col text-left font-roboto font-bold"
          >
            <div className="">
              <img src={uttarakhanadLogo} className="h-96" alt={'sfa logo'} />
            </div>
            {/* <div className="mx-20 py-6 mr-8 mb-0 right-tagline font-bold font-poppins">
            Organize,
            <br />
            Play & <br />
            Track The
            <br />
            Easy Way
          </div> */}
            {/* <div className="middle-line bg-opacity-50 w-16 ml-20 mb-10 mt-0  h-1 bg-white" />
          <p className="px-20 py-0 right-info text-left text-white">
            Organize tournaments, manage teams, score <br />
            matches & keep track of your performance in <br />
            every match you play. It's Free!
          </p> */}
          </div>
          <div id="blue-bg" className="w-full lg:w-1/2 md:bg-white md:py-0">
            <img
              src={SFALogo}
              alt="Sfa Play Mobile Logo"
              className="block md:hidden h-12 p-2"
              onClick={() => {
                history.push('/');
              }}
            />
            <div className="py-8 bg-white px-5 md:px-32 lg:px-20 xl:px-32  h-full flex flex-col md:justify-center text-left">
              <div
                className="hidden lg:block absolute top-4 ml-3 cursor-pointer hover:bg-gray-100 rounded-md p-3 py-2 left-1/2"
                onClick={() => history.push('/signin')}
              >
                <FontAwesomeIcon icon={faArrowLeft} className="mx-2" />
                Login
              </div>
              <div className="action text-xl md:text-3.5xl mb-2 md:mb-0">Reset Password</div>
              <div className="pb-8 md:pb-10 info">
                Create a new and strong password for your account
              </div>
              <Form id="reset-password-form">
                <label className="font-semibold text-sm text-gray-650 pb-2 md:pb-1 block">
                  New Password
                </label>
                <div className="flex flex-wrap w-full relative bg-white items-center border-2 rounded-lg">
                  <div className="flex justify-center items-center pl-3 w-15">
                    <FontAwesomeIcon icon={faLock} className="text-gray-450 " />
                  </div>
                  <Field
                    type="password"
                    name="new"
                    placeholder="********"
                    className="flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none"
                  />
                </div>
                <ErrorMessage name="new" component="div" className="flex-1 text-red-400" />
                <label className="font-semibold text-sm  mt-4 text-gray-650 pb-2 md:pb-1 block">
                  Confirm Password
                </label>
                <div className="flex flex-wrap w-full relative bg-white items-center border-2 rounded-lg">
                  <div className="flex justify-center items-center pl-3 w-15">
                    <FontAwesomeIcon icon={faLock} className="text-gray-450 " />
                  </div>
                  <Field
                    type="password"
                    name="confirm"
                    placeholder="********"
                    className="flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none"
                  />
                </div>
                <ErrorMessage name="confirm" component="div" className="flex-1 text-red-400" />
                {invalid.invalid ? <div className="mt-3 text-red-700">{invalid.msg}</div> : null}
                <div className="flex flex-col center flex-row-reverse md:flex-row 2xl:flex-row justify-center w-full">
                  <button
                    type="submit"
                    className="reg-btn-dark w-full ml-1 rounded-lg my-8 px-10 py-2 text-white"
                    disabled={isSubmitting}
                  >
                    Reset Password
                  </button>
                </div>
              </Form>
              <p className="absolute lg:relative  text-gray-700 text-sm lg:bottom-0 lg:mr-0 bottom-10 mr-2">
                This site is portected by reCaptcha and{' '}
                <a
                  href={process.env.PUBLIC_URL + '/static/terms_and_conditions.pdf'}
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms & Conditions
                </a>{' '}
                apply.
              </p>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
